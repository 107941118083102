<template>
  <div >
    <el-row  class="processManage" v-if="flag == 1" >
      <el-row :gutter="4" class="elRow" >
        <el-col :span="3">
          <el-select
            size="small"
            style="width: 100%"
            v-model="form.workflowType"
            :placeholder="$t('Process.ProcessSettings.Choose')"
          >
            <el-option
              v-for="item in options"
              :key="item.id"
              :label="$t(`Process.ProcessSettings.Options.${item.label}`)"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="3">
          <el-input
            v-model="searchName"
            :placeholder="$t('Process.ProcessSettings.Placeholder')"
            size="small"
            @keyup.enter.native="searchList('1')"
          ></el-input>
        </el-col>
        <el-col :span="3">
          <el-button type="primary"  size="small" @click="searchList('1')">{{$t("public.Inquire")}}</el-button>
        </el-col>
      </el-row>
      <el-row :gutter="0" class="elRow" >
        <el-button type="primary" @click="addNewFlow" class="addbtn" size="small">{{$t("public.New")}}</el-button>
        <el-button type="info" class="delbtn" size="small" v-if="delarr == ''" disabled>{{ $t("public.Delete") }}</el-button>
        <el-button type="primary" class="delbtn" size="small" v-else @click="delArr(delarr)">{{ $t("public.Delete") }}</el-button>
      </el-row>
      <!-- 列表 -->
      <el-row :gutter="4" class="elRow" style="position: relative">
        <el-table :cell-style="{padding:'3px 0px'}" :header-row-style="{height:'30px'}" ref="tableData" :default-sort="{ prop: 'UPDATE_TIME', order: 'descending' }" :data="tableData" size="mini" stripe tooltip-effect="dark" class="tableClass" style="width: 100%;" @selection-change="delobj" @sort-change="sort">
          <el-table-column  header-align="center"  align="center"  type="selection" width="60"> </el-table-column>
          <el-table-column width="180" prop="workflowName" :label="$t('Process.ProcessSettings.Name')" :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column
            prop="workflowType"
            width="180"
            :label="$t('Process.ProcessSettings.WorkflowType')"
            :show-overflow-tooltip="true"
          >
            <template v-slot="{ row }">
              <span v-if="row.workflowType == '1'">{{$t('Process.ProcessSettings.WorkflowType1')}}</span>
              <span v-if="row.workflowType == '2'">{{$t('Process.ProcessSettings.WorkflowType2')}}</span>
              <span v-if="row.workflowType == '3'">{{$t('Process.ProcessSettings.WorkflowType3')}}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="applicantSopeStr"
            :label="$t('Process.ProcessSettings.ApplicantSopeStr')"
            width="180"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              <span
                type="text"
                @click="ScopeOfPersonnel(scope.row.applicantSope, scope.row)"
                style="color: #d38a08; font-size: 12px; cursor: pointer"
                >{{ scope.row.applicantSopeStr }}</span
              >
            </template>
          </el-table-column>
          <!-- v6.1.2新增 -->
          <el-table-column
            prop="contentStr"
            :label="$t('Process.ProcessSettings.ContentStr')"
            width="180"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              <span
                type="text"
                @click="Approver(scope.row)"
                style="color: #d38a08; font-size: 12px; cursor: pointer"
              >
                {{ scope.row.contentStr }}</span
              >
            </template>
          </el-table-column>
          <el-table-column
            prop="creator"
            :label="$t('Process.ProcessSettings.Creator')"
            sortable="custom"
            width="180"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              <div>
                {{ scope.row.creator }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="groupFullName"
            :label="$t('public.Section')"
            sortable="custom"
            width="180"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              <span v-if="scope.row.groupFullName">
                {{ scope.row.groupFullName.substr(groupFullNameShowByIndex) }}
              </span>
            </template>
          </el-table-column>
          <el-table-column
            prop="UPDATE_TIME"
            :label="$t('Process.ProcessSettings.UpdateTime')"
            sortable="custom"
            width="180"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              {{ scope.row.updateTime | dateformat }}
            </template>
          </el-table-column>
          <el-table-column :label="$t('public.Controls')">
            <template slot-scope="scope">
              <img
                @click="edit(scope.row)"
                src="@/assets/icon_write.png"
                style="width: 15px; height: 15px; cursor: pointer"
              />
              <img
                @click="del(scope.row.id)"
                src="@/assets/icon_del.png"
                style="width: 15px; height: 15px; margin-left: 5px; cursor: pointer"
              />
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页 -->
        <pagination
          style="margin-left: 10px"
          :page="currentPage4"
          :limit="pageSize"
          :total="total"
          :pageSizes="[10, 20, 30, 40]"
          v-on:handleChildGetList="handleParentGetList"
        ></pagination>
        <!-- //mouseLeave鼠标移出事件弹出框隐藏 -->
      </el-row>
    </el-row>
    <!-- 新增 -->
    <div v-else>
      <workFlow
        v-bind:sendId="sendId"
        v-on:flags="changeFlag"
        @approEvent="approverCheng"
      ></workFlow>
    </div>
    <!-- 查看人员范围 -->
    <Eldialog
      @handleClose="handleClose"
      :title="$t('Process.ProcessSettings.ApplicantSopeStr')"
      :dialogVisible="outerVisible"
      :flagbtn="false"
    >
      <el-table
        :cell-style="{ padding: '3px 0px' }"
        :header-row-style="{ height: '30px' }"
        ref="showScope"
        :data="showScope"
        row-key="id"
        lazy
        size="mini"
        stripe
        tooltip-effect="dark"
        style="width: 98%; margin-left: 10px"
      >
        <el-table-column prop="loginname" :label="$t('public.Username')" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column prop="username" :label="$t('public.Name')" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column prop="groupFullName" :label="$t('public.Section')" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <span v-if="scope.row.groupFullName">
              {{ scope.row.groupFullName.substr(groupFullNameShowByIndex) }}
            </span>
          </template>
        </el-table-column>
      </el-table>
      <el-button
        style="float: right; margin: 10px 0 0 0"
        type="primary"
        @click="outerVisible = false"
        size="mini"
        >{{$t('Process.ProcessSettings.EndBtn')}}</el-button
      >
    </Eldialog>
    <!-- 查看审批人 -->
    <Eldialog
      @handleClose="handleClose"
      :title="$t('Process.ProcessSettings.ContentStr')"
      :dialogVisible="approverVisible"
      :flagbtn="false"
    >
      <el-table
        :cell-style="{ padding: '3px 0px' }"
        :header-row-style="{ height: '30px' }"
        ref="stepScope"
        :data="stepScope"
        row-key="id"
        lazy
        size="mini"
        stripe
        tooltip-effect="dark"
        style="width: 98%; margin-left: 10px"
      >
        <el-table-column
          width="80"
          prop="step"
          :label="$t('Process.ProcessSettings.StepScope')"
          :show-overflow-tooltip="true"
        >
        </el-table-column>
        <el-table-column prop="loginName" :label="$t('public.Username')" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column prop="userName" :label="$t('public.Name')" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column prop="groupFullName" :label="$t('public.Section')" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <span v-if="scope.row.groupFullName">
              {{ scope.row.groupFullName.substr(groupFullNameShowByIndex) }}
            </span>
          </template>
        </el-table-column>
      </el-table>
      <el-button
        style="float: right; margin: 10px 0 0 0"
        type="primary"
        @click="approverVisible = false"
        size="mini"
        >{{$t('Process.ProcessSettings.EndBtn')}}</el-button
      >
    </Eldialog>
    <!-- 保存成功的弹框 -->
    <dialog-info
      :dialogInfo="Successdialog"
      @handleClose="handleClose"
      @determine="determine"
      :titleInfo="seccess"
      :width="'240px'"
    />
  </div>
</template>
<script>
import pagination from "@/components/pagination/page";
import workFlow from "./addNewWorkFlow.vue";
import Eldialog from "@/components/elDialog/index.vue";
import dialogInfo from "@/components/promptMessage/index";
export default {
  components: { pagination, workFlow, Eldialog, dialogInfo },

  data() {
    return {
      pickerOptions1: {},
      pickerOptions0: {},
      options: [
        {
          value: "",
          label: "流程类型",
        },
        {
          value: "1",
          label: "应用上架",
        },
        {
          value: "2",
          label: "应用下架",
        },
        {
          value: "3",
          label: "设备注册",
        },
      ],
      tableData: [],
      multipleSelection: [],
      //   分页
      currentPage4: 1, //当前页
      pageSize: 10, //每页显示条数
      total: 0,
      flag: 1,
      id: [],
      searchName: "",
      form: {
        workflowType: "",
        workflowName: "",
        applicantType: "",
        applicantSope: "1",
        content: "",
        memo: "",
        edge: "",
        vertex: "",
      },
      Successdialog: false, //控制弹出
      Sencond: 5, //设置初始倒计时
      isDisabled: false,
      time: null,
      seccess: "",
      delarr: [],
      // 全选弹框
      isCheckShow: false,
      // 全选框双向绑定
      checked: false,
      outerVisible: false,
      approverVisible: false, //审批人弹框
      sendId: {}, //传递给子组件
      showScope: [], //查看申请人员范围
      stepScope: [], //查看审批人
      flowType: [{ value: "1", label: "应用上架" }],
      orderColume: "UPDATE_TIME", //排序字段
      orderRule: "DESC", //排序规则ASC、DESC
      // v6.1.2 新增
      receivedData: "",
      IDs: "",
      groupFullNameShowByIndex: "",
    };
  },

  watch: {},
  created() {},

  mounted() {
    this.groupFullNameShowByIndex = sessionStorage.getItem("groupFullNameShowByIndex");
    // 列表
    this.getDataList();
  },

  methods: {
    approverCheng() {
      // console.log(
      //   window.sessionStorage.getItem("approver"),
      //   "window.sessionStorage.getItem----"
      // );
      // this.receivedData = window.sessionStorage.getItem("approver");
      // console.log(this.receivedData, "9999------00000");
    },
    //   子传父 切换flag 提示弹框
    changeFlag(info) {
      this.searchName = "";
      this.getDataList();
      this.flag = 1;
      // if (info.code == '200') {
      //   this.$message.success('保存成功')
      // } else if (info.code == '101') {
      //   this.seccess = info.seccess
      //   this.Successdialog = true
      // }
    },
    handleClose() {
      this.outerVisible = false;
      this.approverVisible = false; // 审批人弹框
      this.Successdialog = false; //提示消息  关闭
    },
    determine() {
      this.Successdialog = false; //提示消息  关闭
    },
    // 新增
    addNewFlow() {
      this.sendId.id = null;
      this.flag = 2;
    },
    // 查询列表
    async searchList(val) {
      if (val == 1) {
        this.currentPage4 = 1;
      }
      var param = {
        currentPage: this.currentPage4,
        pageSize: this.pageSize,
        orderColume: this.orderColume,
        orderRule: this.orderRule,
        workflowName: this.searchName,
        workflowType: this.form.workflowType,
      };
      // console.info(param);
      const res = await this.$axios.post("/httpServe/workFlow/getDataInfo", param, true);
      // this.tableData = res.data.content.filter((item) => {
      //   return item.workflowName.includes(this.searchName);
      // });
      this.tableData = res.data.content;
      this.tableData.forEach((item) => {
        var contentStr = "";
        const datasS = JSON.parse(item.content);
        const filteredData = datasS
          .filter((obj) => obj.name !== 2)
          .map((obj) => obj.user);
        console.log(filteredData, "filteredData");
        const userNames = filteredData.join(",");
        console.log(userNames);
        // 转换为数组并截取 - 以后的内容
        const extractedContentArray = userNames.split(",").map((userName) => {
          const [_, username] = userName.split("-");
          return username;
        });
        var myString = extractedContentArray.toString();
        contentStr = this.removeLeadingOrTrailingCommas(myString);
        this.$set(item, "contentStr", contentStr);
      });
      console.log(this.tableData, "this.tableData处理完的表格数据");
      this.total = res.data.total;
    },
    // 查看申请人员
    async ScopeOfPersonnel(id, info) {
      console.log(id, "idididiidididiid");
      this.outerVisible = true;
      var param = {
        name: id,
      };
      // console.info(param, "=============");
      const res = await this.$axios.post("/httpServe/admin/detail", param, true);
      // console.log(res, "res");
      this.showScope = res.data;
      // console.log(this.showPersonnel);
    },
    // 查看审批人
    async Approver(id) {
      console.log(id, "ididiidid");
      this.IDs = id;
      console.log(this.IDs.id, "this.IDs");
      this.approverVisible = true; // 审批人弹框
      var param = {
        id: this.IDs.id,
      };
      // console.info(param, "=============");
      console.log(param, "paramparam");
      const res = await this.$axios.post(
        "/httpServe/workFlow/getApproverInfo",
        param,
        true
      );
      console.log(res, "res");
      this.stepScope = res.data;
      // console.log(this.showPersonnel);
    },
    // 响应分页组件查询事件
    handleParentGetList(page, limit) {
      this.currentPage4 = page;
      this.pageSize = limit;
      // 调用查询方法
      this.searchList();
    },
    removeLeadingOrTrailingCommas(str) {
      if (str.charAt(0) === ",") {
        str = str.substring(1); // 删除第一个字符
      }
      if (str.charAt(str.length - 1) === ",") {
        str = str.slice(0, -1); // 删除最后一个字符
      }
      return str;
    },
    // 列表
    async getDataList() {
      var param = {
        currentPage: this.currentPage4,
        pageSize: this.pageSize,
        startTime: "",
        endTime: "",
        workflowType: this.workflowType,
        orderColume: this.orderColume,
        orderRule: this.orderRule,
        workflowName: "",
      };
      // console.log(param);
      const res = await this.$axios.post("/httpServe/workFlow/getDataInfo", param, true);
      // console.log(res.data.content,'=========');
      this.tableData = res.data.content;
      this.tableData.forEach((item) => {
        var contentStr = "";
        const datasS = JSON.parse(item.content);
        const filteredData = datasS
          .filter((obj) => obj.name !== 2)
          .map((obj) => obj.user);
        console.log(filteredData, "filteredData");
        const userNames = filteredData.join(",");
        console.log(userNames);
        // 转换为数组并截取 - 以后的内容
        const extractedContentArray = userNames.split(",").map((userName) => {
          const [_, username] = userName.split("-");
          return username;
        });
        var myString = extractedContentArray.toString();
        contentStr = this.removeLeadingOrTrailingCommas(myString);
        this.$set(item, "contentStr", contentStr);
      });
      console.log(this.tableData, "this.tableData处理完的表格数据");
      this.total = res.data.total;
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    //监听排序
    sort(column) {
      // console.log(column.order,"column.order");
      // console.log(column.prop,"column.prop");
      if (column.order === "ascending") {
        this.orderRule = "ASC";
      } else if (column.order === "descending") {
        this.orderRule = "DESC";
      }
      this.orderColume = column.prop;
      this.getDataList();
    },
    //当前页全选
    curSelection() {
      this.$refs.tableData.toggleAllSelection();
    },
    //所有页全选
    // toggleAllSelection() {
    //   this.$refs.tableData.toggleAllSelection();
    // },
    //取消全选
    toggleSelection(rows) {
      if (rows) {
        rows.forEach((row) => {
          row.forEach((item) => {
            item.checked = true;
          });
          this.$refs.tableData.toggleRowSelection(row);
        });
      } else {
        this.$refs.tableData.clearSelection();
      }
      this.isCheckShow = true;
    },
    GetChild() {
      this.$refs.myChild.getEdgeAndVertexArr(data);
      // console.log(this.$refs.myChild.getEdgeAndVertexArr(), "zxc");
    },

    // 回显
    edit(info) {
      // console.info(info, "回显");
      this.flag = 2;
      // this.form = info;
      this.id = [info.id];
      this.sendId = info;
      this.sendId.id = this.id;
      // console.log(this.sendId.id);
    },
    // 批量删除
    delobj(obj) {
      this.delarr = obj;
    },
    delArr(obj) {
      var ids = [];
      obj.forEach((item) => {
        ids.push(item.id);
      });
      var param = { ids: ids };
      this.$confirm(this.$t('Process.ProcessSettings.DelMessage'), this.$t("public.PromptMessage"), {
        confirmButtonText: this.$t("public.Verify"),
        cancelButtonText: this.$t("public.Cancel"),
        customClass: "persdsd",
        type: "warning",
      })
        .then(async () => {
          // 请求接口
          const res = await this.$axios.post("/httpServe/workFlow/delete", param, true);
          if (res.data == -3) {
            this.$message({
              message: this.$t('Process.ProcessSettings.DelError'),
              type: 'error',
              offset: 3000
            })
            setTimeout(()=>{
              this.getDataList()
            },1000)
          }else {
             this.$message({
              message: this.$t("public.SuccessfullyDeleted"),
              type: 'success',
              offset: 3000
            })
            this.$refs.tableData.clearSelection();
            this.currentPage4 = 1;
            setTimeout(()=>{
              this.getDataList()
            },1000)
          }
        })
        .catch(() => {
          return false;
        });
    },
    // 删除
    del(id) {
      this.$confirm(this.$t('Process.ProcessSettings.DelMessage'), this.$t("public.PromptMessage"), {
        confirmButtonText: this.$t("public.Verify"),
        cancelButtonText: this.$t("public.Cancel"),
        customClass: "persdsd",
        type: "warning",
      })
        .then(async () => {
          // 请求接口
          var parma = { ids: [id] };
          const res = await this.$axios.post("/httpServe/workFlow/delete", parma, true);
          if (res.data == -3) {
            this.$message({
              message: this.$t('Process.ProcessSettings.DelError'),
              type: 'error',
              offset: 3000
            })
            setTimeout(()=>{
              this.getDataList()
            },1000)
          }else {
            console.log(this.$t("public.SuccessfullyDeleted"))
             this.$message({
              message: this.$t("public.SuccessfullyDeleted"),
              type: 'success',
              offset: 3000
            })
            this.currentPage4 = 1;
            this.$refs.tableData.clearSelection();
            setTimeout(()=>{
              this.getDataList()
            },1000)
          }
        })
        .catch(() => {
          return false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.processManage {
  width: 100%;
  height: 100%;
  padding: 10px 10px 0px 10px !important; //页面最外层上右左10
  min-width: 1500px;
  font-family: Microsoft YaHei;
}
.elRow {
  margin-bottom: 10px;
}
.btn {
  background: #4787de;
}

.center {
  margin: 10px 3px;
  justify-content: flex-start;

  .addbtn {
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
  }

  .delbtn {
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
  }
}

.save {
  margin: 20px 0 0 200px;
}

.el-form {
  width: 100%;
  margin-left: 0px;
  margin-top: 25px;
}

.el-form-item__content {
  width: 80%;
  display: flex;
  margin: 0 !important;
}

.el-icon-edit,
#el-icon-close {
  border: 1px solid;
  padding: 3px;
  border-radius: 5px;
  margin-right: 5px;
  color: #4787de;
  font-weight: bold;
}

//全选弹框
.tableCheckBox {
  border: 1px solid #1e89e5;
  border-radius: 3px;
  width: 80px;
  background-color: #fff;
  position: absolute;
  top: 30px;
  left: 1%;
  z-index: 999;

  li {
    list-style: none;
    margin-top: -1px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    color: #575656;
    cursor: pointer;
    font-weight: 400;
    font-family: Microsoft YaHei;
    font-size: 12px;

    div {
      display: inline-block;
    }
  }

  li:hover {
    color: #d38a08;
    text-decoration: underline;
  }
}

.el-form-item__content {
  width: 76%;
  display: flex;
  margin: 0 !important;
}

.el-dialog .el-dialog__body {
  padding: 5px 12px 24px 20px !important;
  height: 100px;
  overflow: auto;
  margin-right: 12px;
}

.el-button + .el-button {
  margin-left: 1px;
}

.processDesign {
  width: 66%;
  height: 500px;
  display: flex;

  .leftDragBox {
    padding: 10px;
    flex: 1;
    height: 100%;
    display: flex;

    .designList {
      width: 80px;
      height: 400px;
      border: 1px solid #b7d3f2;
      border-radius: 3px;
      text-align: center;
      padding: 20px;
      margin-bottom: 60px;
    }

    .designDrag {
      flex: 1;
    }
  }

  .listBox {
    background: #f6f6f7;
    flex: 1;
    height: 100%;
    padding: 10px;
  }
}
</style>
