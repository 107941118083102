<template>
  <div>
    <el-form style="margin-top: 20px" :model="form" :rules="rules" ref="form" label-width="180px" class="demo-ruleForm">
      <el-form-item :label="$t('Process.ProcessSettings.Name')+'：'" style="margin-bottom: 20px" prop="workflowName">
        <el-input type="text" v-model="form.workflowName" clearable size="small" :placeholder="$t('Process.ProcessSettings.Placeholder')"
          style="width: 250px" class="el-input1" maxlength="30"></el-input>
      </el-form-item>
      <el-form-item :label="$t('Process.ProcessSettings.WorkflowType')+'：'" :required="true">
        <el-select v-model="form.workflowType" size="small" class="el-input1" style="width: 250px" :disabled="able">
          <el-option v-for="item in flowType" :key="item.value" :label="$t(`Process.ProcessSettings.FlowType.${item.label}`)" :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item :label="$t('Process.ProcessSettings.ApplicantSope')+'：'" prop="applicantSope">
        <el-radio v-model="radio" label="1">{{$t('Process.ProcessSettings.Person')}}</el-radio>
      </el-form-item>
      <el-form-item :label="$t('Process.ProcessSettings.Design')+'：'">
        <!-- 流程设计大盒子 -->
        <div class="processDesign" style="border: 1px solid #dcdfe6">
          <div class="edgeStyleContainer">
            <!-- 左边 审批流程 -->
            <div class="leftDragBox">
              <div class="toolbarContainer" ref="toolbarContainer">
                <ul>
                  <li v-for="item in toolbarItems" :key="item['name']" ref="dragItem">
                    <!-- {{ item["title"] }} -->
                    <img :src="require('../../../assets/img/approver0.png')" />
                  </li>
                </ul>
              </div>
              <div id="graphContainer" class="graphContainer" ref="container"></div>
            </div>

            <!-- 右边1 设置审批人-->
            <div class="listBox" v-show="showApproval">
              <div class="title">
                <h4 class="breadtitle" style="font-size: 15px; color: #000">
                  {{$t('Process.ProcessSettings.SetApproval')}}
                </h4>
              </div>
              <div style="line-height: 28px">
                <i class="el-icon-caret-right" style="color: #3a82c7; font-size: 14px; padding: 10px"></i>
                <span style="font-size: 14px; color: #000">{{$t('Process.ProcessSettings.ApprovalSet')}}</span>
              </div>
              <div style="
                  font-size: 14px;
                  color: #000;
                  line-height: 20px;
                  padding-left: 10px;
                ">
                {{$t('Process.ProcessSettings.ChoosePerson')}}
              </div>
              <div style="display: flex; height: 80%; padding: 0 20px 20px 10px">
                <!-- 左边 tree控件 -->
                <div style="
                    float: left;
                    width: 50%;
                    height: 100%;
                    border: 1px solid #ccc;
                  ">
                  <div style="
                      font-size: 14px;
                      background: #dedede;
                      padding: 0 10px;
                    ">
                    {{$t('public.Section')}}
                  </div>
                  <!-- input -->
                  <div style="padding: 5px 10px; border-bottom: 1px solid #ccc">
                    <el-input v-model="filterText" :placeholder="$t('Process.ProcessSettings.Sectionplaceholder')" size="small" style="background: #f6f6f7"
                      @input="inputChange">
                    </el-input>
                  </div>
                  <!-- //申请人 模糊搜索弹框 -->
                  <div v-if="filterText" style="height: 73%;">
                    <ul style="
                      overflow: hidden auto;
                      height: 100%;
                      width: 100%;
                      padding: 1px 0px;
                      box-shadow: 0px 0px 10px rgb(232, 232, 232);
                    " class="treeBox">
                      <li class="liHover" style="
                        height: 30px;
                        line-height: 30px;
                        padding-left: 14px;
                        border: 1px solid #c6c6c6;
                        color: gray;
                        margin-top: -1px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: block;
                        cursor: pointer;
                      " :title="item.groupName" v-for="(item, index) in searchResult" :key="index"
                        @click="clickListS(item)">
                        {{ item.groupName }}
                      </li>
                    </ul>
                  </div>
                  <div v-else style="height: 73%;">
                    <el-tree v-if="treeShowFlag" :load="loadNode" lazy node-key="id" class="filter-tree" :data="groupFullNameData"
                      :props="defaultProps" :expand-on-click-node="false" :filter-node-method="filterNode"
                      @node-click="selectAdminS" ref="trees" id="tree"
                      style="overflow: auto; height: 100%; background: #f6f6f7;">
                      <span slot-scope="{ node }" :title="node.label" v-text="node.label" />
                    </el-tree>
                  </div>

                </div>
                <!-- 右边 审批人全选 -->
                <div style="
                    float: right;
                    width: 50%;
                    height: 100%;
                    border: 1px solid #ccc;
                  ">
                  <div style="
                      font-size: 14px;
                      background: #dedede;
                      padding: 0 10px;
                    ">
                    {{$t('Process.ProcessSettings.People')}}
                  </div>
                  <!-- input -->
                  <div style="padding: 5px 10px; border-bottom: 1px solid #ccc">
                    <el-input v-model="searchName1" :placeholder="$t('Process.ProcessSettings.Peopleplaceholder')" @keyup.enter.native="searchUserName1" size="small"
                      style="background: #f6f6f7"></el-input>
                  </div>
                  <el-checkbox-group v-model="checkedAdmin" @change="handleCheckedCitiesChange"  v-show="!showAdminFlag" ref="group1" id="group1"
                    style="overflow: auto; height: 73%; background: #f6f6f7; ">
                    <el-checkbox v-for="item in groupNameData" :label="item.id + ',' + item.loginname" :key="item.id"
                      :disabled="item.checked" @change="checkOne(item.id + '-' + item.loginname)" style="
                        display: block;
                        height: 48px;
                        line-height: 48px;
                        width: 100%;
                        color: rgb(86, 86, 86);
                        text-align: left;
                        padding-left: 10px;
                        border-bottom: 1px solid rgb(216, 233, 202);
                      ">
                      {{ item.loginname }}
                    </el-checkbox>
                  </el-checkbox-group>
                  <!-- 该组织下没有管理员 -->
                  <div v-show="showAdminFlag"
                    style="font-size: 14px;text-align: center;color: #606266;position:absolute;top: 200px;right:300px">{{$t('Process.ProcessSettings.NoAdmin')}}</div>
                </div>
              </div>
            </div>
            <!-- 右边2 设置申请人-->
            <div class="listBox" v-show="showApplicant">
              <div class="title">
                <h4 class="breadtitle" style="font-size: 15px; color: #000">
                  {{$t('Process.ProcessSettings.SetApplicant')}}
                </h4>
              </div>
              <div style="line-height: 28px">
                <i class="el-icon-caret-right" style="color: #3a82c7; font-size: 14px; padding: 10px"></i>
                <span style="font-size: 14px; color: #000">{{$t('Process.ProcessSettings.ApplicantSet')}}</span>
              </div>
              <div style="
                  font-size: 14px;
                  color: #000;
                  line-height: 20px;
                  padding-left: 10px;
                ">
                {{$t('Process.ProcessSettings.ChoosePerson')}}
              </div>
              <div style="display: flex; height: 80%; padding: 0 20px 20px 10px">
                <!-- 左边 tree控件 -->
                <div style="
                    float: left;
                    width: 50%;
                    height: 100%;
                    border: 1px solid #ccc;
                  ">
                  <div style="
                      font-size: 14px;
                      background: #dedede;
                      padding: 0 10px;
                    ">
                    {{$t('public.Section')}}
                  </div>
                  <!-- input -->
                  <div style="padding: 5px 10px; border-bottom: 1px solid #ccc">
                    <el-input v-model="filterText" :placeholder="$t('Process.ProcessSettings.Sectionplaceholder')" size="small" style="background: #f6f6f7"
                      @input="inputChange">
                    </el-input>
                  </div>
                  <!-- //申请人 模糊搜索弹框 -->
                  <div v-if="filterText" style="height: 73%;">
                    <ul style="
                      overflow: hidden auto;
                      height: 100%;
                      width: 100%;
                      padding: 1px 0px;
                      box-shadow: 0px 0px 10px rgb(232, 232, 232);
                    " class="treeBox">
                      <li class="liHover" style="
                        height: 30px;
                        line-height: 30px;
                        padding-left: 14px;
                        border: 1px solid #c6c6c6;
                        color: gray;
                        margin-top: -1px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: block;
                        cursor:pointer;
                      " :title="item.groupName" v-for="(item, index) in searchResult" :key="index"
                        @click="clickList(item)">
                        {{ item.groupName }}
                      </li>
                    </ul>
                  </div>
                  <div v-else style="height: 73%;">
                    <el-tree v-if="treeShowFlag" :load="loadNode" lazy node-key="id" class="filter-tree" :data="groupFullNameData"
                      :props="defaultProps" :expand-on-click-node="false" :filter-node-method="filterNode"
                      @node-click="selectAdmin" ref="tree" id="tree" style="
                      background: #f6f6f7;
                      overflow: auto;
                      height: 100%;
                      overflow-x: auto;
                    ">
                      <span slot-scope="{ node }" :title="node.label" v-text="node.label" />
                    </el-tree>
                  </div>
                </div>
                <!-- 右边 申请人全选 -->
                <div style="
                    float: right;
                    width: 50%;
                    height: 100%;
                    border: 1px solid #ccc;
                  ">
                  <div style="
                      font-size: 14px;
                      background: #dedede;
                      padding: 0 10px;
                    ">
                    {{$t('Process.ProcessSettings.People')}}
                  </div>
                  <!-- input -->
                  <div style="padding: 5px 10px; border-bottom: 1px solid #ccc">
                    <el-input v-model="searchName1" @keyup.enter.native="searchUserName" :placeholder="$t('Process.ProcessSettings.Peopleplaceholder')" size="small"
                      style="background: #f6f6f7"></el-input>
                  </div>
                  <el-checkbox-group v-model="checkedAdmin" @change="handleCheckedCitiesChange"
                    ref="group2" id="group2" style="overflow: auto; height: 73%;background: #f6f6f7; ">
                    <el-checkbox v-for="item in groupNameData" :label="item.id + ',' + item.loginname" :key="item.id"
                      :disabled="item.checked" @change="checkOne(item.id + '-' + item.loginname)" style="
                        display: block;
                        height: 48px;
                        line-height: 48px;
                        width: 100%;
                        color: rgb(86, 86, 86);
                        text-align: left;
                        padding-left: 10px;
                        border-bottom: 1px solid rgb(216, 233, 202);
                      ">
                      {{ item.loginname }}
                    </el-checkbox>
                  </el-checkbox-group>
                  <!-- <div v-show="showAdminFlag"
                    style="font-size: 14px;text-align: center;color: #606266;position:absolute;top: 200px;right:300px">该组织下没有管理员</div> -->
                    
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer" style="margin-left: 180px">
      <el-button type="primary" @click="saveSubmit('form')" size="small" class="save">{{$t('public.Save')}}</el-button>
    </div>
  </div>
</template>
<script>
import mx from "mxgraph";
const mxgraph = mx({
  mxImageBasePath: "./src/assets",
  mxBasePath: "./src",
});
const {
  mxGraph,
  mxClient,
  mxEvent,
  mxUtils,
  mxMultiplicity,
  mxConnectionHandler,
  mxTooltipHandler,
  mxGraphHandler,
  mxImage,
  MxConstants,
} = mxgraph;
const approvalFlowInfo = [
  { id: 2, user: [] },
  { id: 3, user: [] },
];
const content = [];
export default {
  props: ["sendId"],

  data() {
    var validateInput = (rule, value, callback) => {
      if (!this.checkSpecificKey(value)) {
        callback(new Error(this.$t('AppManage.AppManagePage.UploadAndroidApp.Error5')))
      } else {
        callback()
      }
    }
    return {
      rules: {
        workflowName: [
          { required: true, message: this.$t("Process.ProcessSettings.Placeholder"), trigger: 'blur' },
          { validator: validateInput, trigger: ['blur', 'change'] }
        ],
      },
      searchResult: [], //申请人模糊搜索结果
      searchResultS: [], //审批人模糊搜索结果
      currentNodekey: 1, //默认高亮（组织树）
      false: false,
      peopleList: [],
      flag: 2,
      able: false,
      Successdialog: false,
      searchName1: "",
      fontColor: "#C57629",
      toolbarItems: [
        {
          name: "n_approval",
          title: this.$t("Process.ProcessSettings.ContentStr"),
        },
      ],
      treeShowFlag:true,
      showApproval: false,
      showApplicant: true,
      // 树形 数据
      filterText: "", //部门查询
      defaultProps: {
        label: "groupName",
        children: "children",
        isLeaf: "isParent",
      },
      checkAll: false,
      checkedAdmin: [],
      checked: true,
      // isIndeterminate: true,
      multipleSelection: [],
      groupFullNameData: [],
      groupNameData: [],
      showAdminFlag: false,
      mxGraphId: "",
      user: [],
      // content: [],
      form: {
        workflowName: "",
        workflowType: "1",
        applicantType: "1",
        content: [],
        memo: "",
        edge: "",
        vertex: "",
      },
      radio: "1",
      applicantSope1: {
        name: "",
        groupName: "",
      },
      applicantNames: "", //申请人范围
      approverNames: "", //审批人范围
      applicantSope2: {},
      flowType: [
        { value: "1", label: "应用上架" },
        { value: "2", label: "应用下架" },
        { value: "3", label: "设备注册" }
        ],
      gId: "",
      id: [],
      sendData: this.sendId,
      arrEdge: [],
      arrVertex: [
        {
          ID: 2,
          Name: this.$t("Process.ProcessSettings.ApplicantStr"),
          LeftTopX: 190,
          LeftTopY: 60,
          Width: 45,
          Height: 45,
          Style:
            "shape=image;verticalLabelPosition=bottom;verticalAlign=top;fontColor=#C57629;image=../../../assets/img/applicant1.png",
        },
        {
          ID: 3,
          Name: this.$t("Process.ProcessSettings.Over"),
          LeftTopX: 190,
          LeftTopY: 390,
          Width: 45,
          Height: 45,
          Style:
            "shape=image;verticalLabelPosition=bottom;verticalAlign=top;fontColor=#C57629;image=../../../assets/img/ellipse.png",
        },
      ],
      choosePerson: "",
      getNewsData:[],
    };
  },

  watch: {
    // 树形 对关键字进行过滤
    // filterText(val) {
    //   this.$refs.tree.filter(val);
    //   this.$refs.trees.filter(val);
    // },
  },

  beforeCreate() { },
  created() { },
  beforeMount() { },

  mounted() {
    this.form.workflowName = "";
    // console.info(document.body.removeChild(document.getElementsByClassName("mxTooltip")));
    var docu = document.getElementsByClassName("mxTooltip");
    while (docu.length > 0) {
      docu[0].parentNode.removeChild(docu[0]);
    }
    // // console.info(content);
    content.splice(0, content.length);
    // console.info(this.form.workflowType);
    var _this = this;
    // this.getGroupFullNameList(function () {
    _this.$nextTick(() => {
      // _this.selectAdmin();
      // _this.selectAdminS();

      // console.log(_this.form, "this.form");
      if (_this.sendData.id != null) {
        // 编辑
        _this.form = _this.sendData;
        // console.log(_this.form);
        _this.id = _this.form.id;
        _this.able = true;
        // approvalFlowInfo = eval(this.form.content);
        _this.arrEdge = eval(_this.form.edge);
        _this.arrVertex = eval(_this.form.vertex);
        // console.info(eval(_this.form.content));
        _this.applicantNames = _this.form.applicantSope;
        // console.log(_this.applicantNames);
        _this.form.workflowType = _this.form.workflowType.toString();
        eval(_this.form.content).forEach((item) => {
          // console.info(item);
          content.push(item);
          if (item["name"] == "2") {
            let user = item["user"].split(",");
            user.forEach((items) => {
              // console.log(items,"====");
              _this.checkedAdmin.push(items.replace("-", ","));
              // console.log(_this.checkedAdmin, "------");
            });
            // console.log(user);
          }
        });
      } else {
        _this.form.workflowType = _this.flowType[0].value;
      }

      // 创建流程图
      var _t = _this;
      _this.createGraph(function () {
        _t.$nextTick(() => {
          // 创建工具栏
          _t.makeItemDraggable();
          _t.selectApplicantCell(_t.graph);
        });
      });
    });
    // });
  },

  methods: {
    checkSpecificKey(str) {
      var specialKey =
        "/\ |\/|\~|\!|\@|\#|\\$|\%|\^|\&|\*|\(|\)|\+|\{|\}|\:|\<|\>|\?|\[|\]|\,|\/|\;|\'|\`|\=|\\\|\|/"
      for (var i = 0; i < str.length; i++) {
        if (specialKey.indexOf(str.substr(i, 1)) != -1) {
          return false
        }
      }
      return true
    },
    // 新增流程
    saveSubmit(form) {
      // var style = this.graph.getStylesheet().getDefaultVertexStyle();
      this.$refs[form].validate((valid) => {
        var edgeAndVertexArr = this.getEdgeAndVertexArr();
        this.form.edge = JSON.stringify(edgeAndVertexArr.arrEdge); //边
        this.form.vertex = JSON.stringify(edgeAndVertexArr.arrVertex); //角

        if (
          edgeAndVertexArr.arrEdge.length <= 0 ||
          edgeAndVertexArr.arrEdge.length + 1 !=
          edgeAndVertexArr.arrVertex.length ||
          edgeAndVertexArr.arrEdge.length != content.length
        ) {
          this.$alert(this.$t("Process.ProcessSettings.WorkFlowIncomplete"), this.$t("public.PromptMessage"), {
            confirmButtonText: this.$t("public.Verify"),
            type: "warning",
          });
          return false;
        }
        // if (this.form.workflowName == "") {
        //   this.$alert("流程名称不能为空", this.$t("public.PromptMessage"), {
        //     confirmButtonText: this.$t("public.Verify"),
        //     type: "warning",
        //   });
        //   return false;
        // }
        var flag = true;
        content.forEach((item) => {
          if (flag) {
            if (item["user"] == "") {
              flag = false;
            }
          }
        });
        if (!flag) {
          this.$alert(this.$t("Process.ProcessSettings.NodeIncomplete"), this.$t("public.PromptMessage"), {
            confirmButtonText: this.$t("public.Verify"),
            type: "warning",
          });
          return false;
        }
        if (valid) {
          if (this.id != "") {
            this.$confirm(this.$t("Process.ProcessSettings.Cancel"), this.$t("public.PromptMessage"), {
              confirmButtonText: this.$t("public.Verify"),
              cancelButtonText: this.$t("public.Cancel"),
              customClass: "persdsd",
              type: "warning",
            })
              .then(() => {
                this.saveAdd();
              })
              .catch(() => {
                return false;
              });
          } else {
            this.saveAdd();
          }
        } else {
          console.log("error");
          return false;
        }
      });
    },
    async saveAdd() {
      var param = {
        ids: this.id,
        workflowName: this.form.workflowName,
        workflowType: this.form.workflowType,
        applicantType: this.radio,
        applicantSope: this.applicantNames, //申请人范围id
        applicantSopeStr: this.applicantScopeStr, //申请人范围名字
        content: JSON.stringify(content),
        memo: "",
        edge: this.form.edge,
        vertex: this.form.vertex,
      };
      const res = await this.$axios.post(
        "/httpServe/workFlow/save",
        param,
        true
      );
      if(res.data == -2) {
        // this.$message.error('名称已存在!')
        this.$message({
          message: this.$t("Process.ProcessSettings.SameName"),
          type: "error",
          offset: 100,
        });
      }else if(res.data == -4) {
        // this.$message.error('申请人范围已存在!')
        this.$message({
          message: this.$t("Process.ProcessSettings.SameApplicantSopeStr"),
          type: "error",
          offset: 100,
        });
      }else if(res.data == 0) {
        // this.$message.success('保存成功')
        this.$message({
          message: this.$t("public.SuccessfullySaved"),
          type: "success",
          offset: 100,
        });
        this.$emit("flags");
      }else if(res.data == 1) {
        // this.$message.success('保存成功')
        this.$message({
          message: this.$t("public.SuccessfullySaved"),
          type: "success",
          offset: 100,
        });
        this.$emit("flags");
      }
    },
    // 获取流程图边和节点信息数组
    getEdgeAndVertexArr() {
      var mxGraph = this.graph;
      var parent = mxGraph.getDefaultParent();
      var parentChildren = parent.children;
      var arrEdge = []; // 连接线
      var arrVertex = []; // 节点

      // 获取所有信息
      var child, obj;
      for (var i = 0; i < parentChildren.length; i++) {
        child = parentChildren[i];
        if (!child.isVisible()) {
          continue;
        }

        obj = new Object();
        obj.ID = child.id;
        obj.Style = child.style;
        obj.Name = child.value;

        if (child.isEdge()) {
          // 线
          obj.SourceID = child.source.id;
          obj.TargetID = child.target.id;
          arrEdge.push(obj);
        } else if (child.isVertex()) {
          // 图形
          obj.LeftTopX = child.geometry.x;
          obj.LeftTopY = child.geometry.y;
          obj.Width = child.geometry.width;
          obj.Height = child.geometry.height;
          arrVertex.push(obj);
        }
      }
      return {
        arrEdge: arrEdge,
        arrVertex: arrVertex,
      };
    },
    async loadNode(node, resolve) {
      // console.log(node.data.id);
      // if (node.level === 0) {
      // return resolve([{ groupName: "EMM", id: 1 }]);
      // }
      var param = {
        name: "",
        parentId: node.data ? node.data.id : "",
      };
      const res = await this.$axios.post(
        "/httpServe/admin/selectNode",
        param,
        true
      );
      // console.log(res.data, "---");
      res.data.forEach((item, index) => {
        if (item.isParent == "true") {
          item.isParent = false;
        } else {
          item.isParent = true;
        }
      });
      resolve(res.data);
    },
    //搜索接口
    async inputChange(v) {
      // console.log(this.filterText, "this.filterText");
      if (v.length > 0) {
        // this.searchGroup = v
        var params = { searchName: this.filterText }
        const res = await this.$axios.post(
          '/httpServe/groups/selectListByName',
          params,
          true
        )
        // console.log(res, "搜索结果");
        // this.showFlag = true
        // this.treeShow = false
        this.searchResult = res.data.content;
        // console.log(this.$refs, "====refs");
      } else {
        // this.showFlag = false
        // this.treeShow = true
      }
    },
    //申请人 点击搜索结果
    clickList(v) {
      // console.log(v, '----点击搜索++++++')
      // this.currentNodekey = v.id;
      // this.$nextTick(() => {
      //   this.$refs.tree.setCurrentKey(this.currentNodekey); //一定要加这个选中了否则样式没有出来
      // })
      this.selectAdmin(v);
    },
    //审批人  点击搜索结果
    clickListS(v) {
      // console.log(v, '----点击搜索++++++')
      // this.currentNodekey = v.id;
      // this.$nextTick(() => {
      //   this.$refs.trees.setCurrentKey(this.currentNodekey); //一定要加这个选中了否则样式没有出来
      // })
      this.selectAdminS(v);
    },
    // 点击部门出现相对应审批人  传参
    // 拖拽审批人，右侧初始化展示
    async selectAdminS(data) {
      console.log(data,"拖拽")
      this.searchName1 = "";
      this.gId = data.id
      let user = [];
      if (this.mxGraphId == "") {
      } else {
        if (content.length > 0) {
          content.forEach((item) => {
            // console.info(item["name"]);
            if (item["name"] != this.mxGraphId) {
              item["user"].split(",").forEach((items) => {
                user.push(items);
              });
            }
          });
        }
      }
      
      // console.info(user);
      if (data) {
        this.selectUserAdmin('');
        this.showApproval = true;
        this.showApplicant = false;
        // var param = {
        //   gId: data.id,
        //   id: this.id,
        //   workflowType: '',
        //   name: "",
        // };

        // // console.info(param);

        // const res = await this.$axios.post(
        //   "/httpServe/admin/selectAdmin",
        //   param,
        //   true
        // );
        // console.info(res.data);
        // console.log(res, "审批人---------------");
        let newdata = [];
        this.getNewsData.forEach((item) => {
          if (
            user.findIndex(
              (items) => items.split("-")[0] === item.id.toString()
            ) == -1
          ) {
            newdata.push({
              groupFullName: item.groupFullName,
              id: item.id,
              loginname: item.loginname,
              userName: item.userName,
            });
          }
        });
        this.groupNameData = newdata;
        if (this.groupNameData.length <= 0) {
          this.showAdminFlag = true;
        } else {
          this.showAdminFlag = false;
        }
        // console.log(this.groupNameData, "this.groupNameData");
      }
    },
    // 点击部门出现相对应申请人员
    async selectAdmin(data) {
      this.gId = data.id
      let user = [];
      if (this.mxGraphId == "") {
      } else {
        if (content.length > 0) {
          content.forEach((item) => {
            // console.info(item["name"]);
            if (item["name"] != this.mxGraphId) {
              item["user"].split(",").forEach((items) => {
                user.push(items);
              });
            }
          });
        }
      }
      // console.info(user);
      if (data) {
        var param = {
          gId: data.id,
          id: this.id,
          workflowType: this.form.workflowType,
          name: "",
        };

        // console.info(param);

        const res = await this.$axios.post(
          "/httpServe/admin/selectAdmin",
          param,
          true
        );
        // console.info(res.data);
        // console.log(res, "申请人---------------");
        let newdata = [];
        res.data.forEach((item) => {
          if (
            user.findIndex(
              (items) => items.split("-")[0] === item.id.toString()
            ) == -1
          ) {
            newdata.push({
              groupFullName: item.groupFullName,
              id: item.id,
              loginname: item.loginname,
              userName: item.userName,
            });
          }
        });
        this.groupNameData = newdata;
        if (this.groupNameData.length <= 0) {
          this.showAdminFlag = true;
        } else {
          this.showAdminFlag = false;
        }
        // console.log(this.groupNameData, "this.groupNameData");
      }
    },
    // 搜索具体人员
    async searchUserName() {
      let user = [];
      if (this.mxGraphId == "") {
      } else {
        if (content.length > 0) {
          content.forEach((item) => {
            // console.info(item["name"]);
            if (item["name"] != this.mxGraphId) {
              item["user"].split(",").forEach((items) => {
                user.push(items);
              });
            }
          });
        }
      }
      var param = {
        gId: '',
        id: this.id,
        workflowType: this.form.workflowType,
        name: this.searchName1,
      };
      const res = await this.$axios.post(
        "/httpServe/admin/selectAdmin",
        param,
        true
      );

      let newdata = [];
      res.data.forEach((item) => {
        if (
          user.findIndex(
            (items) => items.split("-")[0] === item.id.toString()
          ) == -1
        ) {
          newdata.push({
            groupFullName: item.groupFullName,
            id: item.id,
            loginname: item.loginname,
            userName: item.userName,
          });
        }
      });
      // this.groupNameData = newdata;
      this.groupNameData = newdata.filter((item) => {
        return item.loginname.includes(this.searchName1);
      });
      //   this.selectAdmin();
    },
    async searchUserName1() {
      let user = [];
      if (this.mxGraphId == "") {
      } else {
        if (content.length > 0) {
          content.forEach((item) => {
            // console.info(item["name"]);
            if (item["name"] != this.mxGraphId) {
              item["user"].split(",").forEach((items) => {
                user.push(items);
              });
            }
          });
        }
      }
      var param = {
        gId: '',
        id: this.id,
        workflowType: '',
        name: this.searchName1,
      };
      const res = await this.$axios.post(
        "/httpServe/admin/selectAdmin",
        param,
        true
      );

      let newdata = [];
      res.data.forEach((item) => {
        if (
          user.findIndex(
            (items) => items.split("-")[0] === item.id.toString()
          ) == -1
        ) {
          newdata.push({
            groupFullName: item.groupFullName,
            id: item.id,
            loginname: item.loginname,
            userName: item.userName,
          });
        }
      });
      // this.groupNameData = newdata;
      this.groupNameData = newdata.filter((item) => {
        return item.loginname.includes(this.searchName1);
      });
      //   this.selectAdmin();
    },
    // 点击人员传ID、名字
    handleCheckedCitiesChange(value) {
      // console.log(value);
      this.peopleList = value;
      var id = [];
      var name = [];
      var strs;
      value.forEach((item) => {
        strs = item.split(",");
        id.push(strs[0]);
        name.push(strs[1]);
      });
      // // console.info(this.choosePerson);
      if (this.choosePerson == this.$t("Process.ProcessSettings.ApplicantStr")) {
        let obj1 = [];
        obj1.push(name.join(), id);
        this.applicantNames = id.toString().trim();
        this.applicantScopeStr = name.toString();
      } else if (this.choosePerson == this.$t("Process.ProcessSettings.ContentStr")) {
        let obj2 = [];
        obj2.push(name.join(), id);
        // 全部审批人
        this.approverNames = name.toString();
        // console.log(this.approverNames, "this.approverNames@@@@@@@@");
      }

      var selectCell = this.graph.getSelectionCell();
      //   判断有没有选中申请人或审批人
      if (selectCell == null) {
        // this.$message.error("请选择申请人或审批人");
        this.$message({
          message: this.$t("Process.ProcessSettings.ChooseAPerson"),
          type: "error",
          offset: 100,
        });
        // alert("请选择申请人或审批人");
        return;
      }
      approvalFlowInfo.forEach((item, itemIndex) => {
        if (item.id == selectCell.id) {
          item.user = id;
          return false;
        }
      });
    },
    // 树形 对关键字进行过滤
    filterNode(value, data) {
      if (!value) return true;
      return data.groupName.indexOf(value) !== -1;
    },
    covertfromxmltag(value) {
      value = value.split("&").join("&amp;");
      value = value.split("<").join("&lt;");
      value = value.split(">").join("&gt;");
      value = value.split('"').join("&quot;");
      return value;
    },
    checkOne(value) {
      // console.info(this.checkedAdmin);
      if (this.mxGraphId !== "") {
        if (content.length > 0) {
          content.forEach((item, i) => {
            // // console.info(item);
            // // console.info(this.mxGraphId);
            if (item["name"] == this.mxGraphId) {
              this.user = item["user"].split(",");
              content.splice(i, 1);
              // console.log(content);
            } else {
              this.user = [];
            }
          });
        }
      }
      let flag = this.user.findIndex((item) => item === value);

      if (flag >= 0) {
        this.user.splice(this.user.indexOf(value), 1);
      } else {
        this.user.push(value);
      }
      content.push({
        name: this.mxGraphId,
        user: this.user.join(","),
      });
      this.filterText = "";
    },
    // 设置mxGraph规则
    setMxGraphRules() {
      console.log(321)
      if (this.graph == null) {
        return;
      }
      // mxMultiplicity(source,type,attr,value,min,max,validNeighbors,countError,typeError,validNeighborsAllowed)
      // source:true-规则作用于源，false-规则作用于目标
      // type:源或目标的类型（节点上显示的文字），该参数与第一个参数有关
      // attr:不知道，通常为null
      // value:不知道，通常为null
      // min:最小连线数（可能要配合mxgraph自身的提交功能来用），max:最多连线数。组合使用：0,0不能连接；1，1能连一条；1,2能连两条
      // validNeighbors:规则中与第一个参数相对的节点类型数组
      // countError:连线数量错误提示
      // typeError:对参数validNeighbors以外的节点操作时的错误信息（只能对数组中节点应用规则，否则都视为违规）
      // validNeighborsAllowed:可选择的bool标志,设置为false时，validNeighbors中设置的值为相反含义（除了...的含义）
      this.graph.multiplicities.push(
        new mxMultiplicity( // 限制申请人到审批人
          true,
          this.$t("Process.ProcessSettings.ApplicantStr"),
          null,
          null,
          1,
          1,
          [this.$t("Process.ProcessSettings.ContentStr")],
          this.$t("Process.ProcessSettings.ErrorMessage1"),
          this.$t("Process.ProcessSettings.ErrorMessage2")
        )
      );

      this.graph.multiplicities.push(
        new mxMultiplicity( // 限制申请人被指向的时候
          false,
          this.$t("Process.ProcessSettings.ApplicantStr"),
          null,
          null,
          0,
          0,
          null,
          this.$t("Process.ProcessSettings.ErrorMessage3"),
          null
        )
      ); // Type does not matter

      this.graph.multiplicities.push(
        new mxMultiplicity( // 显示审批人指向其他元素
          true,
          this.$t("Process.ProcessSettings.ContentStr"),
          null,
          null,
          1,
          1,
          [this.$t("Process.ProcessSettings.ContentStr"), this.$t("Process.ProcessSettings.Over")],
          this.$t("Process.ProcessSettings.ErrorMessage4"),
          this.$t("Process.ProcessSettings.ErrorMessage5")
        )
      );

      this.graph.multiplicities.push(
        new mxMultiplicity( // 限制审批人被指向
          false,
          this.$t("Process.ProcessSettings.ContentStr"),
          null,
          null,
          1,
          1,
          [this.$t("Process.ProcessSettings.ApplicantStr"), this.$t("Process.ProcessSettings.ContentStr")],
          this.$t("Process.ProcessSettings.ErrorMessage6"),
          this.$t("Process.ProcessSettings.ErrorMessage7")
        )
      );

      this.graph.multiplicities.push(
        new mxMultiplicity( // 限制结束被指向
          false,
          this.$t("Process.ProcessSettings.Over"),
          null,
          null,
          1,
          1,
          [this.$t("Process.ProcessSettings.ContentStr")],
          this.$t("Process.ProcessSettings.ErrorMessage8"),
          this.$t("Process.ProcessSettings.ErrorMessage9")
        )
      );
    },
    // 设置mxGraph配置
    setMxGraphConfigure() {
      if (this.graph == null) {
        return;
      }

      let that = this;

      that.graph.setMultigraph(false); // 禁止重复连线
      that.graph.setCellsResizable(false); // 禁止改变元素大小
      that.graph.setConnectable(true); // 启用连线
      that.graph.setAllowDanglingEdges(false); // 禁止连接线晃动

      that.graph.setTooltips(true); // 设置自定义提示
      that.graph.getTooltipForCell = function (cell) {
        // 获取节点的tooltip信息
        let title = [];
        content.forEach((item) => {
          if (item["name"] == cell.id) {
            item["user"].split(",").forEach((userItem) => {
              title.push(userItem.split("-")[1]);
            });
          }
        });
        var te = that.covertfromxmltag(title.join(","));
        // console.info(te);
        return te;
      };

      // 定义连线的图片 （鼠标移到元素中间出现的连线图）,参数：图片路径，宽，高
      mxConnectionHandler.prototype.connectImage = new mxImage(
        require("../../../assets/img/connector.gif"),
        16,
        16
      );
      mxTooltipHandler.prototype.isEnabled = function () {
        // tooltip事件可用返回true；返回false时，不显示tooltip
        return true;
      };

      mxGraphHandler.prototype.setMoveEnabled(true); // true-节点可拖动，false-节点不可拖动
    },
    createGraph(callBack) {
      if (!mxClient.isBrowserSupported()) {
        // 判断是否支持mxgraph
        mxUtils.error("Browser is not supported!", 200, false);
        return;
      }

      // container.style.background = 'url("img/' + css +
      // '/mxGraph/images/graph_bg.png")'; //设置图形层的背景图片
      // $('.wrap').append(container);
      let container = document.getElementById("graphContainer");
      this.graph = new mxGraph(container);
      this.graph.setConnectable(true);
      this.graph.setCellsEditable(false);

      mxEvent.disableContextMenu(container); //禁止右键菜单

      this.setMxGraphConfigure();
      this.setMxGraphRules();
      var parent = this.graph.getDefaultParent();
      this.graph.getModel().beginUpdate();
      try {
        // 新增流程图块
        var vertexArr = [];
        // shape=image;verticalLabelPosition=bottom;verticalAlign=top;fontColor=#C57629
        // console.info(this.arrVertex);
        this.arrVertex.forEach((item, itemIndex) => {
          // console.info(item + "===" + itemIndex);
          // // console.info(item, "======");
          let styles = {};
          // styles["fontColor"] = "#C57629";
          styles["fontColor"] = "#C57629";
          styles["shape"] = "image";
          styles["verticalAlign"] = "top";
          styles["verticalLabelPosition"] = "bottom";
          // let img = item.Style.split("image=")[1].split(";")[0];
          // let img = '../../../assets/img/approver.png';
          if (item.ID.toString() == "2") {
            styles["image"] = require("../../../assets/img/applicant1.png");
          } else if (item.ID.toString() == "3") {
            styles["image"] = require("../../../assets/img/ellipse.png");
          } else {
            styles["image"] = require("../../../assets/img/approver1.png");
          }

          let g = this.graph.insertVertex(
            parent,
            item.ID,
            item.Name,
            item.LeftTopX,
            item.LeftTopY,
            item.Width,
            item.Height,
            "style" + itemIndex
          );
          this.graph.getStylesheet().putCellStyle("style" + itemIndex, styles);
          vertexArr.push(g);
        });
        // // console.info(this.graph.getStylesheet().getCellStyle());
        // // console.info(vertexArr[0], "==========1");
        // // console.info(vertexArr[0].prototype.getImage(), "==========2");
        // // console.info(vertexArr[0].getStylesheet().getDefaultVertexStyle());
        // vertexArr[0].getStylesheet().getDefaultVertexStyle()["style"] = vertexArr[0]["style"];
        var graph = this.graph;
        var insertEdge = function (edgeId, source, target) {
          graph.insertEdge(parent, edgeId, "", source, target);
        };
        // // console.info(insertEdge);

        // 新增流程图线
        // console.log(this.arrEdge, vertexArr, "新增流程图线");
        var source, target, flag;
        this.arrEdge.forEach((item, itemIndex) => {
          (source = null), (target = null), (flag = true);
          vertexArr.forEach((vertex, vertexIndex) => {
            if (flag) {
              if (vertex.id == item.SourceID) {
                source = vertex;
              } else if (vertex.id == item.TargetID) {
                target = vertex;
              }

              if (source != null && target != null) {
                insertEdge(item.ID, source, target);
                flag = false;
              }
            }
          });
        });
        // console.info(this.id);
        if (this.id == null) {
          // 新增流程图默认申请人块
          // shape=image;verticalLabelPosition=bottom;verticalAlign=top;fontColor=#C57629
          let styles = {};
          styles["fontColor"] = "#C57629";
          styles["shape"] = "image";
          styles["verticalAlign"] = "top";
          styles["verticalLabelPosition"] = "bottom";
          styles["image"] = require("../../../assets/img/approver1.png");

          this.graph.insertVertex(
            parent,
            item.ID,
            item.Name,
            item.LeftTopX,
            item.LeftTopY,
            item.Width,
            item.Height,
            "style"
          );
          this.graph.getStylesheet().putCellStyle("style", styles);
          styles["fontColor"] = "#C57629";
          styles["shape"] = "image";
          styles["verticalAlign"] = "top";
          styles["verticalLabelPosition"] = "bottom";
          styles["image"] = require("../../../assets/img/ellipse.png");

          this.graph.insertVertex(
            parent,
            item.ID,
            item.Name,
            item.LeftTopX,
            item.LeftTopY,
            item.Width,
            item.Height,
            "style"
          );
          this.graph.getStylesheet().putCellStyle("style", styles);
        } else {
        }

        // graph.insertEdge(parent, null, "", v1, v2);
        // graph.insertEdge(parent, null, "", v2, v3);
        // graph.insertEdge(parent, null, "", v1, v3);
      } finally {
        // Updates the display
        this.graph.getModel().endUpdate();
      }

      // 点击事件
      this.graph.addListener(mxEvent.CLICK, (graph, evt) => {
        // 监听单击事件
        let a = [];
        var e = evt.getProperty("event"); // mouse event
        var cell = evt.getProperty("cell"); // cell may be null
        
        if(cell&&cell.value == this.$t("Process.ProcessSettings.Over")) {
          this.treeShowFlag = false
          // this.groupFullNameData = []
          this.groupNameData = []
        }else {
          this.treeShowFlag = true
        }
        if (!cell || cell.id == 3) {
          // document.getElementsByName('group').style.display = 'none';
          this.$refs.group1.$el.style.display = "none";
          this.$refs.group2.$el.style.display = "none";
          return;
        }

        if (cell) {
          // document.getElementsByName('group').style.display = 'block';
          this.checkedAdmin = [];
          let check = [];
          content.forEach((item) => {
            if (item["name"] == cell.id) {
              check = item["user"].split(",");
            }
          });

          check.forEach((item) => {
            this.checkedAdmin.push(item.replace("-", ","));
          });

          this.$refs.group1.$el.style.display = "block";
          this.$refs.group2.$el.style.display = "block";
          // // console.info(cell);
          this.mxGraphId = cell.id;
          // this.selectAdmin();
          // console.info($refs.tree);
          // this.$refs.tree.$el.firstChild.firstChild.click();
          // console.log(cell, "cell.value");
          if (cell.id * 1 > 2) {
            // console.log(this.groupNameData);
            let data = [];
            this.peopleList.forEach((item) => {
              let arr = item.split(",");
              data.push(arr[0]);
            });
            // console.log(data);
            this.approverNames = data;
            // console.log(this.approverNames, "this.approverNames");
            this.$nextTick(() => {
              this.groupNameData.forEach((item) => {
                data.forEach((newItem) => {
                  if (item.id == newItem) {
                    item.checked = true;
                  }
                });
              });
            });
            // console.log(this.groupNameData);
          }
          if (cell == null || !mxEvent.isLeftMouseButton(e)) {
            return;
          }
          this.choosePerson = cell.value;
          console.info(cell.value, "==================点击事件");
          if (cell.value == this.$t("Process.ProcessSettings.ApplicantStr")) {
            this.showApplicant = true;
            this.showApproval = false;
            this.selectCell = cell;
            this.selectUserAdmin('1');
            this.searchName1 = "";
          } else if (cell.value == this.$t("Process.ProcessSettings.ContentStr")) {
            this.showApproval = true;
            this.showApplicant = false;
            this.selectCell = cell;
            this.selectUserAdmin('');
            this.searchName1 = "";
          }
        }
      });

      // 安装右键点击处理程序
      this.graph.popupMenuHandler.factoryMethod = function (menu, cell, evt) {
        if (cell == null || cell.value == "申请人" || cell.value == "结束") {
          return false;
        }

        // 如果右击在图形元素上
        var graph = this.graph;

        // 添加菜单项，参数：文字，图标，回调函数
        menu.addItem(
          "删除",
          require("../../../assets/img/delete2.png"),
          // "https://192.168.220.93:8011/api/images/mxGraph/delete2.png",
          function (e) {
            graph.removeCells(); // 移除选中节点
            graph.refresh(cell); // 刷新，参数：节点。节点数据修改后需要刷新

            if (cell.isVertex()) {
              approvalFlowInfo.forEach((item, itemIndex) => {
                if (item.id == cell.id) {
                  approvalFlowInfo.splice(itemIndex, 1);
                  return false;
                }
              });
              content.forEach((item, itemIn) => {
                if (item["name"] == cell.id) {
                  content.splice(itemIn, 1);
                }
              });
              // // console.info(e.view.document.documentElement);
              document.getElementById("group2").style.display = "none";
            }
          }
        );
      };
      callBack();
    },
    async selectUserAdmin(workflowType) {
       
      let user = [];
      if (this.mxGraphId == "") {
      } else {
        if (content.length > 0) {
          content.forEach((item) => {
            // console.info(item["name"]);
            if (item["name"] != this.mxGraphId) {
              item["user"].split(",").forEach((items) => {
                user.push(items);
              });
            }
          });
        }
      }
      // console.info(user);
        var param = {
          gId: this.gId,
          id: this.id,
          workflowType: workflowType,
          name: "",
        };
        const res = await this.$axios.post(
          "/httpServe/admin/selectAdmin",
          param,
          true
        );
        this.getNewsData = res.data;
        let newdata = [];
        res.data.forEach((item) => {
          if (
            user.findIndex(
              (items) => items.split("-")[0] === item.id.toString()
            ) == -1
          ) {
            newdata.push({
              groupFullName: item.groupFullName,
              id: item.id,
              loginname: item.loginname,
              userName: item.userName,
            });
          }
        });
        this.groupNameData = newdata;
        if (this.groupNameData.length <= 0) {
          this.showAdminFlag = true;
        } else {
          this.showAdminFlag = false;
        }
    },
    makeItemDraggable() {
      this.$nextTick(() => {
        const domArray = this.$refs.dragItem;
        if (!(domArray instanceof Array) || domArray.length <= 0) {
          return;
        }
        domArray.forEach((dom, domIndex) => {
          const toolItem = this.toolbarItems[domIndex];
          const dragHandler = (graph, evt, cell, x, y) => {
            this.instanceGraph(toolItem, x, y);
          };
          const createDragPreview = () => {
            const elt = document.createElement("div");

            elt.style.border = "2px dotted black";
            elt.style.width = "50px";
            elt.style.height = "50px";
            return elt;
          };

          mxUtils.makeDraggable(
            dom,
            this.graph,
            dragHandler,
            createDragPreview(),
            0,
            0,
            false,
            true
          );
        });
      });
    },
    instanceGraph(toolItem, x, y) {
      const parent = this.graph.getDefaultParent();

      this.graph.getModel().beginUpdate();
      try {
        const vertex = this.graph.insertVertex(
          parent,
          null,
          this.$t("Process.ProcessSettings.ContentStr"),
          x,
          y,
          50,
          50,
          "style"
        );

        let styles = {};
        styles["fontColor"] = "#C57629";
        styles["shape"] = "image";
        styles["verticalAlign"] = "top";
        styles["verticalLabelPosition"] = "bottom";
        styles["image"] = require("../../../assets/img/approver1.png");
        this.graph.getStylesheet().putCellStyle("style", styles);
        this.graph.clearSelection(); // 清除选中状态
        this.graph.setSelectionCell(vertex); // 设置选中状态,参数：需要选中的节点;选中多个节点时用setSelectionCell,参数为节点数组
        // // console.info(this.$refs.tree);
        //this.$refs.group1.$el.style.display='block';
        this.$refs.group2.$el.style.display = "block";
        this.mxGraphId = vertex.id;
        this.choosePerson = vertex.value;
        // console.info(this.$refs.trees);

        // 当点击树形结构的时候
        if(this.$refs.trees){
          this.$refs.trees.$el.firstChild.firstChild.click();
        }
        approvalFlowInfo.push({ id: vertex.id, user: [] });
        Reflect.defineProperty(vertex, "customerStatus", {
          set(value) {
            statusMap[vertex["id"]] = value;
            changeStatusHandler(vertex);
          },
          get() {
            return statusMap[vertex["id"]];
          },
        });
      } finally {
        this.graph.getModel().endUpdate();
      }
    },

    selectApplicantCell(graph) {
      var parent = graph.getDefaultParent(); // showTemplateName获取默认的父元素或当前根元素，主要用于获取画布上的所有节点和连线信息
      // // console.info(parent);
      var parentChildren = parent.children; // 获取子节点及连线（数组）
      if (null != parentChildren) {
        for (var i = 0; i < parentChildren.length; i++) {
          var child = parentChildren[i];
          // console.info(child);
          // // console.info(child.isVertex());
          if (child.isVertex()) {
            // 判断元素是否是节点，true-是，false-不是（可能是连线）
            if (child.value == this.$t("Process.ProcessSettings.ApplicantStr")) {
              // 申请
              graph.clearSelection(); // 清除选中状态
              graph.setSelectionCell(child); // 设置选中状态,参数：需要选中的节点;选中多个节点时用setSelectionCell,参数为节点数组
              // // console.info(this.$refs.tree);
              this.mxGraphId = child.id;
              this.choosePerson = child.value;
              // console.info(this.form.workflowType);
              this.$refs.tree.$el.firstChild.firstChild.click();

              // break;
            }
          }
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.edgeStyleContainer {
  width: 100%;
  height: 100%;
  display: flex;
}

.leftDragBox {
  width: 60%;
  height: 100%;
  display: flex;
}

.listBox {
  background: #f6f6f7;
  width: 40%;
  height: 100%;
}

.toolbarContainer {
  flex: 1;
  border: 1px solid #b7d3f2;
  border-radius: 3px;
  text-align: center;
  padding: 20px;
  border: 1px solid #b5d1f1;

  ul {
    padding-left: 0;

    li {
      font-weight: 600;
      list-style: none;
      margin-bottom: 5px;
      cursor: pointer;
      transition: all 0.5s;

      &:hover {
        background-color: #b4e1ff;
      }
    }
  }
}

.graphContainer {
  flex: 7;
  height: 100%;
}

.legendContainer {
  position: absolute;
  top: 5%;
  right: 5%;
  z-index: 2;
  background-color: #fff;

  ul {
    padding: 0;
    margin: 0;

    li {
      list-style: none;

      .legendColor {
        display: inline-block;
        width: 10px;
        height: 10px;
      }
    }
  }
}

.processDesign {
  width: 90%;
  height: 500px;
  display: flex;
}

.leftDragBox {
  padding: 10px;
  width: 54%;
  height: 100%;
  display: flex;
}

.listBox {
  background: #f6f6f7;
  width: 46%;
  height: 100%;
}

// 列表头input
.el-table th.ts>.cell {
  padding: 0;

  .el-input {
    padding: 0;
  }
}

// .el-tree-node.is-expanded>.el-tree-node__children {
//     display: block;
//     overflow: hidden;
//     overflow-x: auto !important;
//     height: 252px !important;
// }
// ::v-deep .el-tree > .el-tree-node {
// display: inline-block;
// overflow-x: auto;
// }
::v-deep .el-form-item__content {
  font-size: 12px;
}

// .filter-tree {
//   font-size: 12px;
//   ::v-deep.el-tree-node__content {
//     width: 200px;
//     overflow: hidden;
//     white-space: nowrap;
//     text-overflow: ellipsis;
//     display: block;
//   }
// }
.el-form-item__label {
  padding: 0 0px 0 0 !important;
}

// ::v-deep .filter-tree[data-v-2415274d] .el-tree-node__content {
//   width: 260px;
// }
.liHover:hover {
  cursor: pointer;
  background-color: #ffe6b0;
}
</style>